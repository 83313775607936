import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {notification} from 'antd';
import {
    DeviceOTAInfoResponse,
    DeviceOTAResponse,
    getDevicesListResponse,
    IAPIError,
    newDeviceOTARequest, OtaInProgressResponse,
} from './types';

const fetcher = async <T, V>(url: string, config?: AxiosRequestConfig, notif?: boolean): Promise<T | AxiosError<V>> => {
    try {
        const {data} = await axios(url, config)

        console.log(notif)
        if (notif === true) {
            notification.success({
                message: "call succeeded",
                description: "OK"
            })
        }

        return data
    } catch (e) {
        const {response} = e as AxiosError<IAPIError>

        notification.error({
            message: `${response?.status} ${response?.statusText}`,
            description: response?.data?.message
        })
        return e as AxiosError<V>
    }
}


class fleetManagerAPI {
    constructor(private fetcher: <T, V>(url: string, params?: any, notif?: boolean) => Promise<T | AxiosError<V>>) {
    }

    private api: string = `/api/fleet-manager/v1`

    private apiToken: string | null = null

    public setApiToken(token: string | null) {
        this.apiToken = token
    }

    public resetApiToken() {
        this.setApiToken(null)
    }

    private get authHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${this.apiToken}`
            }
        }
    }

    private call<T, V>(path: string, config?: AxiosRequestConfig, notif?: boolean): Promise<T | AxiosError<V>> {
        return this.fetcher<T, V>(`${this.api}/${path}`, {
                ...this.authHeaders,
                ...config,
            },
            notif,
        )
    }

    public getInProgress(): Promise<OtaInProgressResponse | AxiosError<unknown>> {
        return this.call<OtaInProgressResponse, unknown>('in-progress')
    }

    public reset(device: string): Promise<string | AxiosError<unknown>> {
        return this.call<string, string>(`devices/reset`, {method: 'post', data: {serial: device}}, true)
    }

    public dryRunDeviceOTA(request: newDeviceOTARequest): Promise<string | DeviceOTAInfoResponse> {
        return this.call<DeviceOTAInfoResponse, string>(`devices?dry-run=true`, {
            method: 'post',
            data: request
        }).then((data) => {
            if (data instanceof AxiosError) {
                if (data.response?.status === 400) {
                    return ""
                }
                if (data.response?.status === 409) {
                    return ""
                    // const reasons = data.response
                    // return reasons//?.join(",") ?? "error"
                }
                return ""
            }
            return data
        })
    }

    public requestDeviceOTA(request: newDeviceOTARequest): Promise<string | AxiosError<DeviceOTAResponse>> {
        return this.call<string, DeviceOTAResponse>(`devices`, {method: 'post', data: request}).then((data) => {
            if (data instanceof AxiosError) {
                if (data.response?.status === 400) {
                    return "Bad Request"
                }
                if (data.response?.status === 409) {
                    const reasons = data.response?.data?.reasons
                    return reasons?.join(",") ?? "error"
                }
                return "Unknown error"
            }
            return "OK"
        })
    }

    public requestOperationsOTA(deviceType :string, downgrade: boolean): Promise<string | AxiosError<unknown>> {
        const data = { deviceType: deviceType, downgrade : downgrade }
        return this.call<string, string>(`operations`, {method: 'post', data: [data]}, true)
    }

    public requestEerbeekOTA(): Promise<string | AxiosError<unknown>> {
        return this.call<string, string>(`eerbeek`, {method: 'post', data: [{}]}, true)
    }

    public requestShipmentOTA(deviceType :string, downgrade: boolean): Promise<string | AxiosError<unknown>> {
        const data = { deviceType: deviceType, downgrade : downgrade }
        return this.call<string, string>(`shipment`, {method: 'post', data: [data]}, true)
    }

    public requestFieldTestOTA(): Promise<string | AxiosError<unknown>> {
        return this.call<string, string>(`field-test`, {method: 'post', data: [{}]}, true)
    }

}

export const fleetManager = new fleetManagerAPI(fetcher)
