import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {FirebaseOptions} from 'firebase/app';
import {notification} from 'antd';
import {SEARCH_PARAMS} from '../URLs';
import {
    IAPIError,
    IAuthResponse,
    getReportsListResponse,
    getNodesResponse,
    getDeviceReportResponse,
    getCultivationReportResponse,
    getCultivationInfoResponse,
    getMeasurementsResponse,
    DeviceType,
    getCultivationsResponse,
    getCultivationsByFarmResponse,
    getInternalNodesResponse,
    NewDeviceComment,
    getFarmsResponse,
    getInternalWeatherStationsResponse,
    getFirmwareStatisticsResponse, getInternalNodeStatusResponse, getStaleDevicesReportResponse,
} from './types';

const fetcher = async <T>(url: string, config?: AxiosRequestConfig): Promise<T | AxiosError> => {
    try {
        const {data} = await axios(url, config)
        return data
    } catch (e) {
        const {response} = e as AxiosError<IAPIError>

        notification.error({
            message: `${response?.status} ${response?.statusText}`,
            description: response?.data?.message
        })
        return e as AxiosError
    }
}

class dashAPI {
    constructor(private fetcher: <T>(url: string, params?: any) => Promise<T | AxiosError>) {
    }

    private apiV3: string = `/api/v3`

    private apiToken: string | null = null

    public setApiToken(token: string | null) {
        this.apiToken = token
    }

    public resetApiToken() {
        this.setApiToken(null)
    }

    private get authHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${this.apiToken}`
            }
        }
    }

    private callAPIv3<T>(path: string, config?: AxiosRequestConfig): Promise<T | AxiosError> {
        return this.fetcher<T>(`${this.apiV3}/${path}`, {
            ...this.authHeaders,
            ...config
        })
    }

    public authenticate(fbtoken: string): Promise<IAuthResponse | AxiosError> {
        return this.callAPIv3<IAuthResponse>('authenticate', {
            headers: {
                Authorization: `Bearer ${fbtoken}`
            }
        })
    }

    public getFireBaseConfig(): Promise<FirebaseOptions | AxiosError> {
        return this.callAPIv3<FirebaseOptions>('firebase-config')
    }

    public getReportsList() {
        return this.callAPIv3<getReportsListResponse>('internal/reports')
    }

    public getDeviceReport(reportType: string, deviceType: DeviceType, statsTime?: string) {
        var requestUrl = `internal/reports/${reportType}?${SEARCH_PARAMS.DEVICE_TYPE}=${deviceType}`
        if (statsTime) {
            requestUrl += `&stats-time=${statsTime}`
        }
        return this.callAPIv3<getDeviceReportResponse>(requestUrl)
    }

    public getCultivationReport(reportType: string, statsTime?: string) {
        var requestUrl = `internal/reports/${reportType}`
        if (statsTime) {
            requestUrl += `?stats-time=${statsTime}`
        }
        return this.callAPIv3<getCultivationReportResponse>(requestUrl)
    }


    public getStaleDevicesReport() {
        return this.callAPIv3<getStaleDevicesReportResponse>(`internal/reports/stale-devices`)
    }

    public getInternalNodes() {
        return this.callAPIv3<getInternalNodesResponse>('internal/nodes')
    }

    public getInternalWeatherStations() {
        return this.callAPIv3<getInternalWeatherStationsResponse>('internal/weather-stations')
    }

    public getInternalFarms() {
        return this.callAPIv3<getFarmsResponse>('internal/farms')
    }

    public getNodes() {
        return this.callAPIv3<getNodesResponse>(`internal/nodes`)
    }

    // public getNodes(deviceId: string) {
    //     return this.callAPIv3<getNodesResponse>(`nodes/${deviceId}`)
    // }

    public getInternalNodeStatus(deviceId: string) {
        return this.callAPIv3<getInternalNodeStatusResponse>(`internal/nodes/${deviceId}/status`)
    }

    public getFirmwareStatistics() {
        return this.callAPIv3<getFirmwareStatisticsResponse>('internal/firmwares/statistics')
    }

    public getCultivationInfo(farmId: string, cultivationId: string) {
        return this.callAPIv3<getCultivationInfoResponse>(`farms/${farmId}/cultivations/${cultivationId}`)
    }

    public getCultivationMeasurements(farmId: string, cultivationId: string) {
        return this.callAPIv3<getMeasurementsResponse>(`farms/${farmId}/cultivations/${cultivationId}/measurements`)
    }

    public getCultivations() {
        return this.callAPIv3<getCultivationsResponse>(`internal/cultivations`)
    }

    public getCultivationsByFarm(farmId: string) {
        return this.callAPIv3<getCultivationsByFarmResponse>(`farms/${farmId}/cultivations`)
    }

    public addComment(comment: NewDeviceComment) {
        return this.callAPIv3<string>(`internal/comments`, {method: 'post', data: comment})
    }

    public deleteComment(commentId: string) {
        return this.callAPIv3<string>(`internal/comments/${commentId}`, {method: 'delete'})
    }

    public editComment(commentId: string, comment: NewDeviceComment) {
        return this.callAPIv3<string>(`internal/comments/${commentId}`, {method: 'put', data: comment})
    }
}

export const API = new dashAPI(fetcher)
