import React, {FC, useCallback, useEffect, useState} from 'react';
import {
    AutoComplete,
    AutoCompleteProps,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Result,
    Row,
    Space,
    Table,
    TableProps,
    Tag,
    Tooltip,
    Typography
} from 'antd';
import {
    API,
    DeviceOTAInfo,
    DeviceOTAInfoResponse,
    fleetManager,
    getInternalNodesResponse,
    getInternalWeatherStationsResponse,
    newDeviceOTARequest,
    OtaInProgressEntry,
    OtaInProgressResponse,
} from '../../api'
import {ResultStatusType} from "antd/es/result";
import {NodeInternalListItem, WeatherStationInternalListItem,} from "../../api/internal_types";
import {getTableFilters, localeTableSorter, numberTableSorter, onFilterTableHandler} from "../../common/utils";

interface OtaRequestsPageProps {
}



export const OtaRequestsPage: FC<OtaRequestsPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const [nodes, setNodes] = useState<NodeInternalListItem[]>([]);
    const [weatherStations, setWeatherStations] = useState<NodeInternalListItem[]>([]);

    const [nodeOptions, setNodeOptions] = useState<AutoCompleteProps['options']>([]);
    const [weatherStationOptions, setWeatherStationOptions] = useState<AutoCompleteProps['options']>([]);

    const [selectedNodes, setSelectedNodes] = useState<NodeInternalListItem[]>([]);
    const [selectedWeatherStations, setSelectedWeatherStations] = useState<WeatherStationInternalListItem[]>([]);

    const [selectedSerials, setSelectedSerials] = useState<string>("");
    const [result, setResult] = useState<ResultStatusType | undefined>();
    const [error, setError] = useState<string | undefined>();

    const [dryRun, setDryRun] = useState<DeviceOTAInfo[] | undefined>();

    const [downgrade, setDowngrade] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<OtaInProgressEntry[]>([]);

    useEffect(() => {
        //just combine all selected serials
        setSelectedSerials([...selectedNodes, ...selectedWeatherStations].map(it => it.id).join(":"))
    }, [setSelectedSerials, selectedNodes, selectedWeatherStations])

    useEffect(() => {
        setLoading(true);

        let count = 0
        API.getInternalNodes().then(data => {
            setNodes((data as getInternalNodesResponse).nodes.filter(it => it.farm.id !== ""))
            count++
            setLoading(!(count === 2));
        })

        API.getInternalWeatherStations().then(data => {
            setWeatherStations((data as getInternalWeatherStationsResponse).weatherStations.filter(it => it.farm.id !== ""))
            count++
            setLoading(!(count === 2));
        })

    }, [setLoading]);

    const submitOTA = useCallback(() => {
        setResult(undefined)
        setLoading(true);

        const requested = [
            ...(selectedNodes.map(it => {
                return {serial: it.id, deviceType: 'node', downgrade: downgrade}
            })),
            ...(selectedWeatherStations.map(it => {
                return {serial: it.id, deviceType: 'weatherStation', downgrade: downgrade}
            })),
        ] as newDeviceOTARequest;

        fleetManager.requestDeviceOTA(requested).then((res) => {
            console.log(res)
            setResult(res === "OK" ? "success" : "warning")
            setError(res.toString())
            setLoading(false)
        })
    }, [selectedNodes, selectedWeatherStations, setResult, setError, downgrade])

    const verifyOTA = useCallback(() => {
        setResult(undefined)
        setLoading(true);

        const requested = [
            ...(selectedNodes.map(it => {
                return {serial: it.id, deviceType: 'node', downgrade: downgrade}
            })),
            ...(selectedWeatherStations.map(it => {
                return {serial: it.id, deviceType: 'weatherStation', downgrade: downgrade}
            })),
        ] as newDeviceOTARequest;

        fleetManager.dryRunDeviceOTA(requested).then((res) => {
            console.log(res)
            setDryRun((res as DeviceOTAInfoResponse).ota)
            // setResult(res === "OK" ? "success" : "warning")
            // setError(res.toString())
            setLoading(false)
        })
    }, [selectedNodes, selectedWeatherStations, setDryRun, setError, downgrade])

    const shipmentOTA = useCallback((deviceType: string) => {
        fleetManager.requestShipmentOTA(deviceType, downgrade)
    }, [downgrade])

    const operationsOTA = useCallback((deviceType: string) => {
        fleetManager.requestOperationsOTA(deviceType, downgrade)
    }, [downgrade])

    const eerbeekOTA = useCallback(() => {
        fleetManager.requestEerbeekOTA()
    }, [downgrade])


    const loadInProgress = useCallback(() => {
            fleetManager.getInProgress().then((it) => {
                    setInProgress((it as OtaInProgressResponse).inProgress)
                }
            ).catch((e) => console.log(e))
        }
        , [setInProgress])

    const onSelectNode = useCallback((data: string) => {
        if (selectedSerials.indexOf(data) === -1) {
            const device = nodes.find(it => it.id === data)
            console.log("select", data, device)
            if (device !== undefined) {
                setSelectedNodes([...selectedNodes, device])
            }
        }

    }, [nodes, selectedSerials, selectedNodes, setSelectedNodes]);

    const onSelectWeatherStation = useCallback((data: string) => {
        if (selectedSerials.indexOf(data) === -1) {
            const device = weatherStations.find(it => it.id === data)
            if (device !== undefined) {
                setSelectedWeatherStations([...selectedWeatherStations, device])
            }
        }
    }, [weatherStations, selectedSerials, selectedWeatherStations, setSelectedWeatherStations]);

    const getPanelValueNodes = (searchText: string) => {
        let items = nodes.filter(it => selectedSerials.indexOf(it.id) === -1)
        items = !searchText ? items : items.filter(it => it.id.indexOf(searchText) >= 0)

        return items.map(it => {
            return {label: `${it.id} @${it.firmwareVersion}`, value: it.id,}
        });
    }

    const getPanelValueWeatherStations = (searchText: string) => {
        let items = weatherStations.filter(it => selectedSerials.indexOf(it.id) === -1)
        items = !searchText ? items : items.filter(it => it.id.indexOf(searchText) >= 0)

        return items.map(it => {
            return {label: `${it.id} @${it.firmwareVersion}`, value: it.id,}
        });
    }

    const unselect = (serial: string) => {
        setSelectedNodes(selectedNodes.filter(it => it.id !== serial));
        setSelectedWeatherStations(selectedWeatherStations.filter(it => it.id !== serial));
    }

    const onSubmit = () => {
        submitOTA()
    }

    const onVerify = () => {
        verifyOTA()
    }

    const onClear = () => {
        setSelectedNodes([]);
        setSelectedWeatherStations([]);
    }

    const onDismiss = () => {
        setResult(undefined)
    }

    const onLoadInProgress = () => {
        loadInProgress()
    }

    const resetNode = (item: OtaInProgressEntry) => {
        console.log("reset node", item)
        fleetManager.reset(item.serial).then((data) => {
            console.log("reset node - result", data)
        })
    }

    const columns: TableProps<OtaInProgressEntry>['columns'] = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showSorterTooltip: true,
            sorter: localeTableSorter<OtaInProgressEntry>('serial'),
            filters: getTableFilters<OtaInProgressEntry>(inProgress, 'serial'),
            onFilter: onFilterTableHandler<OtaInProgressEntry>('serial'),
            render: (text) => <div>{text}</div>,
        },
        {
            title: 'Current',
            dataIndex: 'currentImage',
            key: 'current',
            render: (text) => <div>{text}</div>,
        },
        {
            title: 'Target',
            dataIndex: 'targetImage',
            key: 'target',
            render: (text) => <div>{text}</div>,
        },
        {
            title: 'Requested At',
            dataIndex: 'dateTime',
            key: 'timestamp',
            render: (text) => <div>{text.toString().substring(0, 10)}</div>,
        },
        {
            title: 'Retries',
            dataIndex: 'retries',
            key: 'retries',
            sorter: numberTableSorter<OtaInProgressEntry>('retries'),
            // filters: [{ text: "maxed-out", value: 30 }],
            filters: getTableFilters<OtaInProgressEntry>(inProgress, 'retries'),
            onFilter: onFilterTableHandler<OtaInProgressEntry>('retries'),
            render: (text, item) => {
                if (text >= 20) {
                    return <div><span>{text}</span><a onClick={() => {
                        resetNode(item)
                    }}>&nbsp;reset</a></div>
                } else {
                    return <div>{text}</div>
                }
            },
        },
    ]

    return (
        <div className="ota-requests-page">
            <Typography.Title level={3}>OTA requests</Typography.Title>
            <br/>

            <Col>
                <Row>
                    <Card title="Node" size="small" style={{marginRight: 32}} loading={loading}>
                        <AutoComplete
                            options={nodeOptions}
                            style={{width: 350}}
                            onSelect={onSelectNode}
                            onSearch={(text) => setNodeOptions(getPanelValueNodes(text))}
                            placeholder="input here"
                        />
                    </Card>

                    <div>
                        <Divider orientation={"left"}>Selected Nodes</Divider>
                        {selectedNodes.map(it => {
                            return (<Tag closable key={it.id} color={"magenta"}
                                         onClose={() => unselect(it.id)}>{it.id} (@{it.firmwareVersion})</Tag>)
                        })}
                    </div>

                </Row>
                <br/>
                <Row>
                    <Card title="Weather Station" size="small" style={{marginRight: 32}} loading={loading}>
                        <AutoComplete
                            options={weatherStationOptions}
                            style={{width: 350}}
                            onSelect={onSelectWeatherStation}
                            onSearch={(text) => setWeatherStationOptions(getPanelValueWeatherStations(text))}
                            placeholder="input here"
                        />
                    </Card>
                    <div>
                        <Divider orientation={"left"}>Selected Weather Stations</Divider>
                        {selectedWeatherStations.map(it => {
                            return (
                                <Tag closable key={it.id} color={"green"}
                                     onClose={() => unselect(it.id)}>{it.id} (@{it.firmwareVersion})</Tag>)
                        })}
                    </div>
                </Row>
                <br/>
                <Col>
                    <Space direction={"horizontal"} size={"small"}>
                        <Button color={"primary"} onClick={onVerify}>Verify OTA</Button>
                        <Button color={"primary"} onClick={onSubmit}>Request OTA</Button>
                    </Space>
                    <br/><br/>
                    <Tooltip title={"will force an OTA to stable"}>
                        <Checkbox checked={downgrade} onChange={(it) => setDowngrade(it.target.checked) }>Downgrade</Checkbox>
                    </Tooltip>

                    <Divider orientation="left">Bulk OTA</Divider>

                    <Space direction={"horizontal"} size={"small"}>
                        <Card title={"Shipment"}>
                            <Tooltip title={"mark all shipment nodes for OTA (2-depth)"}>
                                <Button color={"primary"} onClick={() => shipmentOTA("node-2-depth")}>Nodes (2-depth)</Button>
                            </Tooltip>&nbsp;&nbsp;
                            <Tooltip title={"mark all shipment nodes for OTA (4-depth)"}>
                                <Button color={"primary"} onClick={() => shipmentOTA("node-4-depth")}>Nodes (4-depth)</Button>
                            </Tooltip>&nbsp;&nbsp;
                            <Tooltip title={"mark all shipment weather stations for OTA"}>
                                <Button color={"primary"} onClick={() => shipmentOTA("weatherStation")}>Weather Stations</Button>
                            </Tooltip>
                        </Card>
                        <Card title={"Operations"}>
                            <Tooltip title={"mark all operations nodes for OTA (2-depth)"}>
                                <Button color={"primary"} onClick={() => operationsOTA("node-2-depth")}>Nodes (2-depth)</Button>
                            </Tooltip>&nbsp;&nbsp;
                        <Tooltip title={"mark all operations nodes for OTA (4-depth)"}>
                            <Button color={"primary"} onClick={() => operationsOTA("node-4-depth")}>Nodes (4-depth)</Button>
                        </Tooltip>&nbsp;&nbsp;
                        <Tooltip title={"mark all operations weather stations for OTA"}>
                            <Button color={"primary"} onClick={() => operationsOTA("weatherStation")}>Weather Stations</Button>
                        </Tooltip>
                        </Card>

                        <Card title={"Eerbeek"}>
                            <Tooltip title={"mark all eerbeek nodes for OTA"}>
                                <Button color={"primary"} onClick={() => eerbeekOTA()}>Nodes</Button>
                            </Tooltip>&nbsp;&nbsp;
                        </Card>
                    </Space>
                    <br/><br/>
                    <Tooltip title={"will force an OTA to stable"}>
                        <Checkbox checked={downgrade} onChange={(it) => setDowngrade(it.target.checked) }>Downgrade</Checkbox>
                    </Tooltip>

                    {result !== undefined && <Result
                        status={result}
                        title="Result of OTA request"
                        subTitle={error}
                        extra={[
                            <Button key="clear" onClick={onClear}>Clear</Button>,
                            <Button key="done" onClick={onDismiss}>Dismiss</Button>,
                        ]}
                    />}

                    {dryRun !== undefined && <div>
                        <ul>
                            {dryRun.map(it => (<li key={it.serial}>{it.serial} ({it.deviceType}) - {it.cohort} (<i><b>target
                                fw</b></i>: {it.image})</li>))}
                        </ul>
                    </div>}
                </Col>
                <Col>
                    <Divider orientation="left">In Progress devices</Divider>
                    <Button color={"primary"} onClick={onLoadInProgress}>List In Progress</Button>

                    <br/>
                    <br/>
                    {inProgress.length > 0 && <Table<OtaInProgressEntry>
                        columns={columns}
                        dataSource={inProgress}
                        showSorterTooltip={{}}
                    />}
                </Col>
            </Col>
        </div>
    );
};

