import React, {FC, useCallback, useEffect, useState} from 'react';
import {AutoComplete, AutoCompleteProps, Button, Card, Col, Result, Row, Typography} from 'antd';
import {
    API, getInternalNodesResponse,
    getInternalNodeStatusResponse,
} from '../../api'
import {NodeInternalListItem} from "../../api/internal_types";

interface NodeStatusPageProps {
}

interface Props {
    data: getInternalNodeStatusResponse
}

export const NodeStatus = ({ data }: Props) => {

    const items = [
        {title: "Active", value: data.stats.active},
        {title: "Air Measurements", value: data.stats.airMeasurements},
        {title: "Bad soil contact", value: data.stats.badSoilContact},
        {title: "Bad soil contact (v3)", value: data.stats.badSoilContactV3},
        {title: "Battery depletion", value: data.stats.batteryDepletion},
        {title: "Battery low", value: data.stats.batteryLow},
        {title: "Battery medium", value: data.stats.batteryMedium},
        {title: "Cultivation missing", value: data.stats.cultivationMissing},
        {title: "Data transmission gaps", value: data.stats.dataTransmissionGaps},
        {title: "Empty sensor measurements", value: data.stats.emptySensorMeasurements},
        {title: "Idle mode depletion", value: data.stats.idleModeDepletion},
        {title: "Inclination High", value: data.stats.inclinationHigh},
        {title: "Incorrect GPS", value: data.stats.incorrectGPS},
        {title: "Inverted VWC patterns", value: data.stats.invertedVWCPatterns},
        {title: "Invalid sensor measurements", value: data.stats.invalidSensorMeasurements},
        {title: "Large VWC difference", value: data.stats.largeVWCDifference},
        {title: "Low VWC or EC", value: data.stats.lowVWCOrEC},
        {title: "Low battery level crop sensor", value: data.stats.lowBatteryLevelCropSensor},
        {title: "Multiple deployments", value: data.stats.multipleDeployments},
        {title: "No sensor measurements", value: data.stats.noSensorMeasurements},
        {title: "New sensor installed", value: data.stats.newSensorInstalled},
        {title: "Precise bad soil contact", value: data.stats.preciseBadSoilContact},
        {title: "Removed", value: data.stats.removed},
        {title: "Shipments", value: data.stats.shipments},
        {title: "Signal lost", value: data.stats.signalLost},
        {title: "Too dry", value: data.stats.tooDry},
        {title: "Zero GPS", value: data.stats.zeroGPS},
    ]

    const unChanged = new Date(0)

    return (
        <Card title={"Node Stats"}>
            <ul>
                <li>Last Updated: {data.stats.statsTime.toString()}</li>
                {
                    items.map((item) => (
                        item.value.lastChanged !== undefined &&
                        item.value.lastChanged !== unChanged &&
                        item.value.status &&
                        <li>{item.title}: {item.value.status.toString()} - since {item.value.lastChanged?.toString()?.substring(0, 10)}</li>)
                    )
                }
            </ul>
        </Card>
    )
}

export const NodeStatusPage: FC<NodeStatusPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const [nodes, setNodes] = useState<NodeInternalListItem[]>([]);

    const [nodeOptions, setNodeOptions] = useState<AutoCompleteProps['options']>([]);

    const [node, setNode] = useState<NodeInternalListItem | undefined>();
    const [result, setResult] = useState<getInternalNodeStatusResponse | undefined>();
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        setLoading(true);

        API.getNodes().then(data => {
            setNodes((data as getInternalNodesResponse).nodes.filter(it => it.farm.id !== ""))
            setLoading(false);
        })

    }, [setLoading, setNodes]);

    const onSelectNode = useCallback((data: string) => {
        setNode(nodes.filter(node => node.id === data)[0]);
    }, [nodes, setNode]);

    const getPanelValueNodes = (searchText: string) => {
        const items = !searchText ? nodes : nodes.filter(it => it.id.indexOf(searchText) >= 0)

        return items.map(it => {
            return {label: `${it.id} - ${it.farm.name}`, value: it.id}
        });
    }

    const onSubmit = async () => {
        if (node !== undefined) {
            const data = await API.getInternalNodeStatus(node.id)
            setResult(data as getInternalNodeStatusResponse)
        } else {
            setError("error")
            setResult(undefined)
        }
    }

    const onDismiss = () => {
        setResult(undefined)
    }

    return (
        <div className="node-status-page">
            <Typography.Title level={3}>Node Status</Typography.Title>
            <br/>

            <Col>
                <Row>
                    <Card title="Node" size="small" style={{marginRight: 32}}>
                        <AutoComplete
                            options={nodeOptions}
                            style={{width: 350}}
                            onSelect={onSelectNode}
                            onSearch={(text) => setNodeOptions(getPanelValueNodes(text))}
                            placeholder="input here"
                        />
                    </Card>
                    {node && <Card title={"Info"}>
                        <div>
                            <div>Farm: {node.farm.name}</div>
                            <div>Battery: {node.battery}%</div>
                            <div>Firmware: {node.firmwareVersion}</div>
                        </div>
                    </Card>}
                </Row>
                <br/>
                <Col>
                    <Button color={"primary"} onClick={onSubmit}>Fetch node status</Button>
                    {result !== undefined && <Result
                        status={"success"}
                        title="Current Node Status"
                        subTitle={error}
                        extra={[
                            <Button key="done" onClick={onDismiss}>Dismiss</Button>,
                        ]}
                    ><NodeStatus data={result}/></Result>}
                </Col>
            </Col>
        </div>
    );
};



